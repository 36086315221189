.form-container .form-item.vertical {
    @apply !mb-5;
}

.form-explain {
    @apply text-xs truncate w-full;
    margin-bottom: 3px;
}

.upload-file-list {
    @apply grid gap-2;
}

.upload-file-info > .upload-file-name {
    @apply text-xs;
}
.upload-file-info > .upload-file-size {
    @apply text-xs;
}

.upload-file-image {
    @apply object-cover max-h-12;
}

.divider-title {
    @apply relative;
}

.divider-title__line {
    @apply absolute w-full border-b dark:border-gray-700 h-3;
}

.divider-title__content {
    @apply absolute left-10 bg-white dark:bg-gray-800 px-5;
}

.accordion {
    @apply border rounded-md lg:rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700;
}

.accordion-item {
    @apply border-b last:border-none dark:border-gray-700;
}

.accordion-item-header {
    @apply cursor-pointer px-4 py-3 font-semibold flex items-center justify-between dark:text-gray-200;
}

.accordion-item-content {
    @apply p-4;
}

.accordion-item__active .accordion-item-header {
    @apply border-b dark:border-gray-700;
}

.tynda-setting__control > .form-label {
    @apply text-sm;
}

.tynda-setting__control .select__menu {
    @apply min-w-64;
}

.btn-primary__active {
    @apply border-2 border-primary-500 bg-opacity-20;
}

.btn-gray__active {
    @apply border-2 border-gray-800 bg-opacity-20;
}